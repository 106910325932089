
/**
 * 缓存过code
 */
export const SET_CODE = 'SET_CODE'

/**
 * 缓存简历
 */
export const SET_RESUME = 'SET_RESUME'

/**
 * 缓存微信签名
 */
export const SET_SIGNATURE = 'SET_SIGNATURE'

/**
 * 设置选中的亮点
 */
export const SET_HIGHTLIT_LIST = 'SET_HIGHTLIT_LIST'

/**
 * 缓存发布职位信息2
 */
export const SET_PUBLISH2 = 'SET_PUBLISH2'

/**
 * 缓存发布职位信息
 */
export const SET_PUBLISH1 = 'SET_PUBLISH1'

/**
 * 设置亮点
 */
export const SET_HIGHLIT = 'SET_HIGHLIT'


/**
 * 设置用户信息
 */
export const SET_USER = 'SET_USER'

/**
 * 设置交易记录详情
 */
export const SET_AMOUNT_INFO = 'SET_AMOUNT_INFO'

/**
 * 退款信息
 */
export const SET_REFUND_RESULT = 'SET_REFUND_RESULT'

/**
 * 订单信息
 */
export const SET_ORDER_INFO = 'SET_ORDER_INFO'
import axios from 'axios'
import { SET_CODE } from '@/utils/constant'
import { wxOaTh } from '@/api/index'

axios.defaults.withCredentials = true

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config: any) => {
        if (config.url.indexOf(`/upload/uploadImage`) > -1) {
            /* config.headers = {
                'path': 'avatar'
            }; */
        } else {
            config.data = JSON.stringify(config.data);
            config.headers = {
                "Content-Type": "application/json",
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export const api = {
    upload (url: string, data: any, config: any = '') {
        return new Promise((resolve, reject) => {
            axios.post(url, data, config).then(response => {
                const res = response.data;
                resolve(res)
            })
            .catch((e) => {
                reject(e.message)
            })
        })
    },
    post (url: string, data: any) {
        return new Promise((resolve, reject) => {
            axios.post(url, data, {
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {
                const res = response.data;
                resolve(res)
            })
            .catch((e) => {
                reject(e.message)
            })
        })
    },
    get (url: string, params: any) {
        return new Promise((resolve, reject) => {
            if (params) {
                axios.get(url, params).then(response => {
                    const res = response.data;
                    resolve(res)
                })
                .catch((e) => {
                    reject(e.message)
                }) 
            } else {
                axios.get(url).then(response => {
                    const res = response.data;
                    resolve(res)
                })
                .catch((e) => {
                    reject(e.message)
                })
            }
        })
    }
}

/* const store = useStore() */

/**
 * 用户状态
 */
export const Storage = {
    /**
     * 检测用户登录是否过期,过期则清除缓存
     * @param key 缓存key
     */
    isExpires (key: string): void {
        let item: any = localStorage.getItem(key)
        if(!item)return
        item = JSON.parse(item)
        const date = new Date().getTime()
        if (date - item.startTime > item.expires) {
            // 缓存过期，清除缓存，返回false
           // store.dispatch('clear','')
            this.remove(key)
        }
    },
    get(key: string) {
        const str = localStorage.getItem(key)
        return !str ? '' : JSON.parse(str)
    },
    /**
     * 设置本地缓存
     * @param key 
     * @param value 
     * @param expires 过期时间
     */
    set (key:string, value: any, expires: number) {
        const startTime = new Date().getTime()//记录何时将值存入缓存，毫秒级
        const obj = { user: value, startTime, expires}
        localStorage.setItem(key, JSON.stringify(obj))
    },
    remove (key: string) {
        localStorage.removeItem(key)
    },
    clear () {
        localStorage.clear()
    }    
}

/**
 * 检测是否微信浏览器打开
 */
export const isWechat = () => {
    const ua: any = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    } else {
        return false
    }
}

/**
 * 随机数
 * @param len 
 */
export const randomString = (len: number) => {
    len = len || 32;
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  const maxPos = $chars.length;
   let pwd = '';
　　for (let i = 0; i < len; i++) {
　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
　　}
　　return pwd;
}

/**
 * 计算年龄
 * @param str 
 */
export const ages = (str: any) => {
    const r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);    
    if(r==null)return   false;    
    const d = new Date(r[1], r[3]-1, r[4]);    
    if(d.getFullYear()==r[1]&&(d.getMonth()+1)==r[3]&&d.getDate()==r[4]) {  
          const Y = new Date().getFullYear();  
          return (Y-r[1]) //("年龄   =   "+   (Y-r[1])   +"   周岁");  
    }  
    return "";  
}

/**
 * 面试状态
 * @param key 
 */
export const getStates = (key: number) => {
    const map = new Map([
        [0, '待筛选简历'],
        [200, '面试中'],
        [400, '入职报到'],
        [10000, '已淘汰']
    ])
    return map.get(key) 
}

/**
 * 职位 角色名称
 * @param key 
 */
export const getIdentity = (key: number) => {
    const map = new Map([
        [1, '店长'],
        [2, '区域/商圈经理'],
        [3, '招聘人员'],
        [4, '其他']
    ])
    return map.get(key) 
}

/**
 * 获取参数
 * @param variable 
 */
export const getQueryVariable = (variable: string) => {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i=0;i<vars.length;i++) {
        const pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

const _wxCode = () => {     
    const appid = 'wx5f2d974aa7988c50'
    const redirect_url = encodeURIComponent(window.location.href.split('#')[0])

    console.log(window.location.origin, 'localtion.origin')
    console.log(window.location.href, 'localtion.href')
    console.log(window.location.href.split('#')[0], 'localtion.href.split')

    const url = `
    https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_base&state=123#wechat_redirect
    `
    window.location.href = url
}


const _wxOaTh = async (code: string) => {
    const bool = localStorage.getItem('w')
    if (bool)return
    const res = await wxOaTh({data: code})
    localStorage.setItem('w', 'w')
    console.log(res, '微信认证打印了什么呢...')
}

/**
 * 获取wx code 缓存
 */
export const wxCode = () => {
    const localCode = localStorage.getItem(SET_CODE)
    const code = getQueryVariable("code")
    if (code) return _wxOaTh(code)
    if (localCode) return
    localStorage.setItem(SET_CODE, 'SET_CODE')
    _wxCode()

    /* let timer: any = setTimeout(() => {
        clearTimeout(timer)
        timer = null 

        const localCode = localStorage.getItem(SET_CODE)
        const code = getQueryVariable("code")
        if (code) {
            console.log(code, '获取的code---')
            _wxOaTh(code)
            return 
        }
        if (localCode) return
        localStorage.setItem(SET_CODE, 'SET_CODE')
        _wxCode()
    }, 500) */
}

export const delay = (fn: any, times: number) => {
    let timers: any = setTimeout(() => {
        clearTimeout(timers)
        timers = null 
        fn()
    }, times)
}


export const timesStr = (date2:any) => {
    //date2 = '2022-08-03 00:00:00'
    const sdate = new Date();  //开始时间  
    const now = new Date(date2);    //结束时间  
    const days= now.getTime() - sdate.getTime()  //时间差的毫秒数  
    const day = Math.floor(days/( 24 * 3600 * 1000))  
    
    //计算出小时数  
    const leave1=days%(24*3600*1000)    //计算天数后剩余的毫秒数  
    const hours=Math.floor(leave1/(3600*1000))  
    return {day, str: day + "天 "+hours+"小时 "}
}
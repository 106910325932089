import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isWechat } from '@/utils/index'
import * as useStore from '@/store'
import { Toast } from 'vant'

const store = useStore.default

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      return '/login'
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
        title: '登录'
    },
    component: () => import('../views/system/login/index')
  },
  {
    path: '/login2',
    name: 'Login2',
    meta: {
        title: '登录',
        Auth: false
    },
    component: () => import('../views/system/login/index2')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: '门店账号注册',
      Auth: false
   },
    component: () => import('../views/system/register/index')
  },
  {
    path: '/job',
    name: 'Job',
    meta: {
       title: '职位管理',
       Auth: true
    },
    component: () => import('../views/job/index')
  },
  {
    path: '/highlights',
    name: 'Highlights',
    meta: {
       title: '职位亮点',
       Auth: true
    },
    component: () => import('../views/highlights/index')
  },
  {
    path: '/publish',
    name: 'Publish',
    meta: {
       title: '发布职位',
       Auth: true
    },
    component: () => import('../views/job/publish/index')
  },
  {
    path: '/publish2',
    name: 'Publish2',
    meta: {
       title: '发布职位',
       Auth: true
    },
    component: () => import('../views/job/publish/index2')
  },
  {
    path: '/info',
    name: 'Info',
    meta: {
       title: '职位详情',
       Auth: true
    },
    component: () => import('../views/job/info/index')
  },{
    path: '/info2',
    name: 'Info2',
    meta: {
       title: '职位详情',
       Auth: true
    },
    component: () => import('../views/job/info/index2')
  },
  {
    path: '/resumeJob',
    name: 'ResumeJob',
    meta: {
       title: '查看简历-职位列表',
       Auth: true
    },
    component: () => import('../views/resume/job')
  },
  {
    path: '/resume',
    name: 'Resume',
    meta: {
       title: '查看简历-简历列表',
       Auth: true
    },
    component: () => import('../views/resume/index')
  },
  {
    path: '/resumeInfo',
    name: 'ResumeInfo',
    meta: {
       title: '查看简历-简历详情',
       Auth: true
    },
    component: () => import('../views/resume/info')
  },
  {
    path: '/areement',
    name: 'Areement',
    meta: {
       title: '用户协议',
       Auth: false
    },
    component: () => import('../views/system/areement')
  },
  {
    path: '/agreement',
    name: 'Argeement',
    meta: {
       title: '充值协议',
       Auth: true
    },
    component: () => import('../views/system/agreement')
  },
  {
    path: '/flai',
    name: 'flai',
    meta: {
       title: 'Flai',
       Auth: false
    },
    component: () => import('../views/flai'),
    children: [
      { meta: {
        title: 'Flai用户协议',
        Auth: false
     }, path: 'agreement', component: () =>  import('../views/flai/agreement')},
      {meta: {
        title: 'Flai隐私政策',
        Auth: false
     }, path: 'privacy', component: () =>  import('../views/flai/privacy')}
    ]
  },
  {
    path: '/logoff',
    name: 'Logoff',
    meta: {
       title: '注销协议',
       Auth: false
    },
    component: () => import('../views/system/logoff')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
       title: '翻腕隐私政策',
       Auth: false
    },
    component: () => import('../views/system/privacy')
  },
  {
    path: '/personal',
    name: 'Personal',
    meta: {
       title: '个人中心',
       Auth: true
    },
    component: () => import('../views/personal/index')
  },
  {
    path: '/amount',
    name: 'Amount',
    meta: {
       title: '余额管理',
       Auth: true
    },
    component: () => import('../views/system/amount')
  },
  {
    path: '/rechargeInfo',
    name: 'RechargeInfo',
    meta: {
       title: '详情',
       Auth: true
    },
    component: () => import('../views/system/amount/rechargeInfo')
  },
  {
    path: '/refund',
    name: 'refund',
    meta: {
       title: '申请退款',
       Auth: true
    },
    component: () => import('../views/system/amount/refund')
  },
  {
    path: '/refundResult',
    name: 'RefundResult',
    meta: {
       title: '申请退款',
       Auth: true
    },
    component: () => import('../views/system/amount/refundResult')
  },
  {
    path: '/recharge',
    name: 'Recharge',
    meta: {
       title: '账户充值',
       Auth: true
    },
    component: () => import('../views/system/recharge')
  },
  {
    path: '/result',
    name: 'Result',
    meta: {
       title: '账户充值',
       Auth: true
    },
    component: () => import('../views/system/recharge/result')
  },
  {
    path: '/pwd',
    name: 'Pwd',
    meta: {
       title: '修改密码',
       Auth: true
    },
    component: () => import('../views/personal/updatePwd')
  },
  {
    path: '/upInfo',
    name: 'personInfo',
    meta: {
       title: '编辑个人信息',
       Auth: true
    },
    component: () => import('../views/personal/upInfo')
  },
  {
    path: '/upCompany',
    name: 'upCompany',
    meta: {
       title: '编辑门店信息',
       Auth: true
    },
    component: () => import('../views/personal/upCompany')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const bool = isWechat()
  if (to.meta.title) {
    const str: any = to.meta.title
    document.title = str
  }
  if (to.path.includes('/logoff') || to.path.includes('/privacy') 
  || to.path.includes('/areement') || to.path.includes('/flai') 
  ) {
    next()
  }else if (!bool && !to.path.includes('default')) {
    next({path: '/default'})
  }
  // 判断是否要登录
  if (to.meta.Auth) {
     const user: any = store.getters.user 
     const local = localStorage.getItem('SET_USER')
      if (!user || !local) {
          Toast('请先登录');
          next({
             path: '/login', //跳转登录
             query: {
                redirect: to.fullPath //重定向
             } 
          })
      }
  }

  next();
})

export default router

import { createStore } from 'vuex'
import { SET_USER } from '@/utils/constant'
import { Storage } from '@/utils/index'

interface State {
  [propeName: string]: any;
}


export default createStore({
  state: {
    user: {}, //用户
    account: 0, //账户
    rechargeMoney: 0, //充值金额
    amountKey: undefined, // 1:交易 2:充值 3:退款
  },
  getters: {
    amountKey(state) {
      return state.amountKey
    },
    rechargeMoney (state) {
      return state.rechargeMoney
    },
    account (state) {
      //state.account = palyload
      return state.account
    },
    user (state: State) {
      const local: any = localStorage.getItem(SET_USER)
      const status = JSON.stringify(state.user)
      return status == "{}" ? JSON.parse(local) : state.user
   }
  },
  mutations: {
    setAmountKey(state, playload) {
      state.amountKey = playload
    },
    setRechargeMoney(state, playload) {
       state.rechargeMoney = playload
     },
    setAccount (state, playload) {
     // debugger
      state.account = playload
    },
    // 设置用户信息
      setUser(state: State, playload: State) {
        Storage.set(SET_USER, playload, 1000 * 60 * 60 * 24 * 30)
        state.user = playload
    },
    /**
      * 清除缓存
      * @param state 
      * @param playload 
      */
    clear (state: State, playload: State) {
      if (!playload) {
        state.user = {}
      }
    }
  },
  actions: {
    setAmountKey({ commit }, playload) {
      commit('setAmountKey', playload)
   },
    setRechargeMoney({ commit }, playload) {
       commit('setRechargeMoney', playload)
    },
    setAccount ({ commit }, playload) {
      //debugger
      commit('setAccount', playload)
    },
    // 设置用户信息
    setUser({ commit }: any, playload: State) {
      Storage.set(SET_USER, playload, 1000 * 60 * 60 * 24 * 30)
      commit('setUser', playload)
    }
  }
})
